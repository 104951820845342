import styled from "@emotion/styled";
import { FC, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { auth } from "../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const Card = styled.div`
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;
`;

const Email = styled.div`
  color: #000;
  margin-top: 15px;
`;

const Avatar = styled.div`
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a3a3a3;
`;

const MenuItem = styled.button`
  height: 52px;
  color: #000;
  text-align: left;
`;

const PopoverMenu: FC = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/signin");
  }, [user, loading]);

  const onLogOut = async () => {
    const auth = getAuth();
    await signOut(auth);
    navigate("/signin");
  };

  return (
    <>
      <Card>
        <Avatar>
          <AiOutlineUser size={30} />
        </Avatar>
        <Email>tsiry.sndr@gmail.com</Email>
      </Card>
      <Flex
        flexDirection={"column"}
        style={{ paddingLeft: 15, paddingRight: 15 }}
      >
        <MenuItem onClick={() => navigate("/settings")}>Settings</MenuItem>
        <MenuItem onClick={() => navigate("/reports")}>Reports</MenuItem>
        <MenuItem onClick={() => navigate("/help")}>Help</MenuItem>
        <MenuItem onClick={onLogOut} style={{ color: "red" }}>
          Sign out
        </MenuItem>
      </Flex>
    </>
  );
};

export default PopoverMenu;
