import styled from "@emotion/styled";
import { AiOutlineUser } from "react-icons/ai";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import PopoverMenu from "./PopoverMenu";
import { useLocation } from "react-router-dom";

const Container = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgb(238, 238, 238);
`;

const Logo = styled.a`
  width: 200px;
  font-weight: bold;
`;

const AccountMenu = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 24px;
`;

const Account = styled.div`
  margin-right: 15px;
`;

const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #14ff272b;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateCheckoutButton = styled.button`
  background-color: #4724fe;
  border-radius: 20px;
  height: 40px;
  color: white;
  width: 170px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

function Header() {
  const { pathname } = useLocation();
  return (
    <Container>
      <Logo href="/#/">Commerce</Logo>
      <RightContainer>
        {pathname === "/checkouts" && (
          <CreateCheckoutButton>Create a checkout</CreateCheckoutButton>
        )}
        <Popover>
          <PopoverTrigger>
            <AccountMenu>
              <Account>tsiry.sndr@gmail.com</Account>
              <Avatar>
                <AiOutlineUser color="#14ff27" />
              </Avatar>
            </AccountMenu>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody style={{ height: 397 }}>
              <PopoverMenu />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </RightContainer>
    </Container>
  );
}

export default Header;
