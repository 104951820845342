import { FC } from "react";
import Wrapper from "../../Components/Wrapper";

const Checkouts: FC = () => {
  return (
    <div>
      <Wrapper>Checkouts</Wrapper>
    </div>
  );
};

export default Checkouts;
