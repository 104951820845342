import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

export class ApolloClientManager {
  static createClient() {
    const httpLink = createHttpLink({
      uri: "http://localhost:4002/api",
    });

    const wsLink = new WebSocketLink({
      uri: "ws://localhost:4002/socket/websocket",
      options: {
        reconnect: true,
      },
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await localStorage.getItem("access_token");
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      authLink.concat(httpLink)
    );

    return new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache(),
      //  defaultOptions: {watchQuery: {fetchPolicy: 'cache-and-network'}},
    });
  }
}
