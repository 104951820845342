import { FC } from "react";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import Wrapper from "../../Components/Wrapper";

const Balance = styled.div`
  font-size: 28px;
`;
const Container = styled.div`
  padding: 25px;
  flex: 1;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const BalanceWrapper = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const WithdrawButton = styled.button`
  color: #000;
  background-color: #00ffc6;
  width: 142px;
  height: 52px;
  border-radius: 26px;
`;

const Label = styled.div`
  font-size: 14px;
`;

const Home: FC = () => {
  return (
    <div>
      <Wrapper>
        <Container>
          <BalanceWrapper>
            <Flex flex={1} flexDirection={"column"}>
              <Balance>10,000.00 MGA</Balance>
              <Label>Available balance</Label>
            </Flex>
            <WithdrawButton>Withdraw</WithdrawButton>
          </BalanceWrapper>
        </Container>
      </Wrapper>
    </div>
  );
};

export default Home;
