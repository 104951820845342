import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Google from "../../Components/Icons/Google";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

const GoogleSignInButton = styled(Button)`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  border: 1px solid #dcdcdc;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

const CreateWalletButton = styled(Button)`
  font-weight: 500;
  display: flex;
  background-color: #4724fe;
  color: #fff;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

const ImportWalletButton = styled(Button)`
  font-weight: 500;
  display: flex;
  color: #4724fe;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const SignIn: FC = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/");
  }, [user, loading]);

  const onSignInWithGoogle = async () => {
    try {
      googleProvider.addScope("https://www.googleapis.com/auth/drive.file");
      const res = await signInWithPopup(auth, googleProvider);
      console.log(res);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  const onCreateWallet = () => {
    navigate("/");
  };

  const onImportWallet = () => {
    navigate("/");
  };

  return (
    <Container>
      <Wrapper>
        <GoogleSignInButton onClick={onSignInWithGoogle} variant={"unstyled"}>
          <Google />
          <div>Sign in with Google</div>
        </GoogleSignInButton>
        <CreateWalletButton onClick={onCreateWallet} variant={"unstyled"}>
          Create a new wallet
        </CreateWalletButton>
        <ImportWalletButton onClick={onImportWallet} variant={"unstyled"}>
          I already have a wallet
        </ImportWalletButton>
      </Wrapper>
    </Container>
  );
};

export default SignIn;
