import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDB_ecaooAdLF6hz-yPyRTqW67Tww03EgU",
  authDomain: "hazavana-commerce-7a224.firebaseapp.com",
  projectId: "hazavana-commerce-7a224",
  storageBucket: "hazavana-commerce-7a224.appspot.com",
  messagingSenderId: "273468839893",
  appId: "1:273468839893:web:b51d96375e978a9fd58f5c",
  measurementId: "G-PR964M0T4R",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
export const googleProvider = new GoogleAuthProvider();

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
