import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Checkouts from "./Containers/Checkouts";
import Help from "./Containers/Help";
import Home from "./Containers/Home";
import Invoices from "./Containers/Invoices";
import Payments from "./Containers/Payments";
import Reports from "./Containers/Reports";
import Settings from "./Containers/Settings";
import SignIn from "./Containers/SignIn";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/checkouts" element={<Checkouts />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </Router>
  );
}

export default App;
