import { Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItem = styled.div<{ active?: boolean }>`
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 30px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      color: #4724fe;
      background-color: #ddd6ff40;
    `}
`;

const Navigator: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Flex
      style={{
        flexDirection: "column",
        borderRight: "solid 1px #eee",
        width: 240,
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      <MenuItem onClick={() => navigate("/")} active={pathname === "/"}>
        <a href="/#/">Balance</a>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/payments")}
        active={pathname === "/payments"}
      >
        <a href="/#/payments">Payments</a>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/invoices")}
        active={pathname === "/invoices"}
      >
        <a href="/#/invoices">Invoices</a>
      </MenuItem>
      <MenuItem
        onClick={() => navigate("/checkouts")}
        active={pathname === "/checkouts"}
      >
        <a href="/#/checkouts">Checkouts</a>
      </MenuItem>
    </Flex>
  );
};

export default Navigator;
