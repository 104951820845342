import { FC } from "react";
import Wrapper from "../../Components/Wrapper";

const Invoices: FC = () => {
  return (
    <div>
      <Wrapper></Wrapper>
    </div>
  );
};

export default Invoices;
