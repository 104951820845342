import { Flex } from "@chakra-ui/react";
import { FC } from "react";
import Header from "../Header";
import Navigator from "../Navigator";

const Wrapper: FC = ({ children }) => {
  return (
    <div>
      <Header />
      <Flex
        style={{
          height: "calc(100vh - 60px)",
        }}
      >
        <Navigator />
        <Flex style={{ flex: 1 }}>{children}</Flex>
      </Flex>
    </div>
  );
};

export default Wrapper;
